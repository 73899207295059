import { Message } from '../message';

export const MESSAGES_es_ES_LANG = 'es-ES';
export const MESSAGES_es_ES_explore = 'es-ES_tradnl';

export const MESSAGES_ES = [
  new Message('user', 'Usuario'),
  new Message('password', 'Contraseña'),
  new Message('welcome', 'Tu espacio para gestionar tu'),
  new Message('welcome2', 'presencia, recursos y servicios'),
  new Message('menu_init', 'Inicio'),
  new Message('menu_room', 'Reserva de salas'),
  new Message('menu_profile', 'Perfil'),
  new Message('menu_descargas', 'Descargas'),
  new Message('menu_incidence', 'Incidencia'),
  new Message('menu_security', 'Seguridad'),
  new Message('menu_job_position', 'Puestos de trabajo'),
  new Message('menu_resource', 'Recursos'),
  new Message('menu_carWash', 'Lavadero de coches'),
  new Message('menu_dorlet', 'Dorlet'),
  new Message('menu_steward', 'Mayordomo'),
  new Message('menu_dinning_position', 'Reserva restaurante'),
  new Message('menu_assistance', 'Presencia'),
  new Message('menu_assistance_user', 'Presencia usuario'),
  new Message('menu_cantine', 'Comedor'),
  new Message('menu_parking', 'Parking'),
  new Message('menu_shuttle', 'Lanzadera'),
  new Message('menu_question_and_answers', 'Soporte'),
  new Message('menu_logout', 'Cerrar sesión'),
  new Message(
    'error_getEquipement',
    'Error al obtener el equipamiento de la sala'
  ),
  new Message('error_getAllEquipement', 'Error al obtener el equipamiento'),
  new Message('error_getRoomDetail', 'Error al obtener el detalle de la sala'),
  new Message('rooms_reserve', 'Salas reservadas'),
  new Message('rooms_disable_reservation_title', 'Salas liberadas'),
  new Message('rooms_disable_reservation_description', 'Hemos liberado las salas de reuniones durante la crisis del COVID-19'),
  new Message('rooms_reserve_empty', 'No hay salas reservadas'),
  new Message(
    'rooms_waiting_get_error',
    'Error al obtener las salas y lista de espera'
  ),
  new Message('waiting_list', 'Lista de espera'),
  new Message('express_reservation_btn', 'Reserva express {0}'),
  new Message('room_reserve_btn', 'Reservar sala'),
  new Message('room_available', 'DISPONIBLE'),
  new Message('room_available_btn', 'Reservar'),
  new Message('room_busy', 'OCUPADA'),
  new Message('room_busy_user', 'OCUPADA POR {0}'),
  new Message(
    'express_resevation_success',
    'Se le ha asignado la sala {0} para el tramo horario {1} ({2} - {3})'
  ),
  new Message('incorrect_login', 'Login incorrecto'),
  new Message('azure_token_renewal_error', 'Error al iniciar sesión en el SSO, por favor cierre el Pop-up y vuelva a intentarlo'),
  new Message('error_forbidden', 'Sesión caducada'),
  new Message('error_required', '* Campo requerido'),
  new Message('error_numeric_required', '* Campo numérico y requerido'),
  new Message('error_license_required', '* Campo matrícula y requerido'),
  new Message('btn_view_rooms', 'Ver salas disponibles'),
  new Message('error_nextBooking', 'Error al obtener la última reserva'),
  new Message('home_events_title', 'PRÓXIMOS EVENTOS'),
  new Message('cancel_reservation_btn', 'Cancelar'),
  new Message('reservation_cancelled', 'Reserva cancelada correctamente'),
  new Message('reservation_cancelled_error', 'Error al cancelar la reserva'),
  new Message(
    'confirm_dialog_err',
    'Error al mostrar la confirmación de cancelación'
  ),
  new Message(
    'reservation_delete_msg',
    '¿Seguro que desea cancelar la reserva?'
  ),
  new Message('reservation_delete_msg_title', 'Cancelación de reserva'),
  new Message('cancel_waiting_msg', '¿Seguro que desea cancelar la espera?'),
  new Message('cancel_waiting_msg_title', 'Cancelación de espera'),
  new Message('cancel_waiting_successfully', 'Espera cancelada correctamente'),
  new Message('cancel_waiting_error', 'Error al cancelar la espera'),
  new Message(
    'cancel_waiting_dialog_error',
    'Error al mostrar la confirmación de cancelación'
  ),
  new Message('express_create_error', 'Error al crear la reserva express'),
  new Message('error_dalkonParking', 'Error al obtener la plaza'),
  new Message('home_parking_title', 'PARKING'),
  new Message('parking_ceded', 'Cedida'),
  new Message('parking_resident', 'Plaza fija'),
  new Message('parking_lot', 'Plaza asignada para hoy'),
  new Message('no_parking', 'Sin plaza'),
  new Message('no_vehicle', 'Sin vehículo'),
  new Message('session_expired', 'Sesión caducada'),
  new Message('schedule', 'Horario'),
  new Message('issue', 'Motivo'),
  new Message('equipment', 'Equipamiento'),
  new Message('time_str', 'Desde {0} hasta {1}'),
  new Message('date', 'Fecha'),
  new Message('assistants', 'Asistentes'),
  new Message('from', 'Desde'),
  new Message('since', 'Hasta'),
  new Message('meeting_reason', 'Motivo de la reunión'),
  new Message(
    'date_from_greater',
    'Fecha desde debe ser mayor que la fecha actual'
  ),
  new Message('date_to_greater', 'Fecha hasta debe ser mayor que fecha desde'),
  new Message('no_available_rooms_msg', 'No hay salas disponibles'),
  new Message(
    'no_available_rooms_equipment_msg',
    'Con el equipamiento que necesita'
  ),
  new Message('rooms_without_equip', 'Salas sin equipamiento'),
  new Message(
    'available_rooms_list_error',
    'Error al obtener las salas disponibles'
  ),
  new Message(
    'room_reserved_msg',
    'Sala {0} reservada: Añadida el día {1} desde {2} hasta {3}'
  ),
  new Message('reserve_creation_error', 'Error al crear la reserva'),
  new Message(
    'room_to_waiting',
    'La reserva ha sido añadida a la lista de espera'
  ),
  new Message(
    'room_to_waiting_error',
    'Error al añadir la reserva a la lista de espera'
  ),
  new Message('waiting_msg', '{0} desde {1} hasta {2}'),
  new Message('reservation_reason_placeholder', 'Escribe un motivo'),
  new Message('edit_reservation_error', 'Error al editar la reserva'),
  new Message('reservation_in_progress', 'En curso'),
  new Message('name', 'Nombre'),
  new Message('building', 'Edificio'),
  new Message('select_building', 'Seleccione edificio'),
  new Message('no_building', 'Sin edificio'),
  new Message('select', 'Seleccione..'),
  new Message('none', 'Ninguno'),
  new Message('not_available', 'No disponible'),
  new Message('all_dept', 'Todos los departamentos'),
  new Message('all_user_dept', 'Todos los usuarios del departamento: '),
  new Message('save_profile_btn', 'Actualizar perfil'),
  new Message('save_building_btn', 'Guardar edificio'),
  new Message('buildings', 'Edificio'),
  new Message('save_building_successfully', 'Edificio guardado correctamente'),
  new Message('save_building_error', 'Error al guardar el edificio'),
  new Message('error_getBuildings', 'Error al obtener el listado de edificios'),
  new Message('room_detail_name_title', 'Sala'),
  new Message('headquarters_list', 'Sede'),
  new Message('country_list', 'Países'),
  new Message('provinces_list', 'Provincias'),
  new Message('headquarters', 'Sede'),
  new Message('getHeadquarters_error', 'Error al obtener las sedes'),
  new Message('getCountry_error', 'Error al obtener los países'),
  new Message('getProvince_error', 'Error al obtener las provincias'),
  new Message(
    'userWithoutHeadquartersMustSelectOne',
    'Por favor, seleccione una sede para empezar a usar la aplicación'
  ),
  new Message('login_access_btn', 'Acceder'),
  new Message('login_forgot_password', 'He olvidado mi contraseña'),
  new Message(
    'login_problem_mail',
    'Si tiene problemas para acceder al servicio contacte con nosotros desde '
  ),
  new Message('login_problem_here', 'aquí'),
  new Message(
    'resetPassword_message_ok',
    'Se le ha enviado un email con una nueva contraseña.'
  ),
  new Message(
    'resetPassword_message_error',
    'Error durante la actualización de la contraseña'
  ),
  new Message('resetPassword_request_btn', 'Solicitar'),
  new Message('resetPassword_return_btn', 'Volver'),
  new Message('parking_nextDraw_message', 'La proxima asignación será el '),
  new Message('parking_yieldToday_message', 'Ceder hoy'),
  new Message('parking_yield_message', 'Ceder plaza'),
  new Message('parking_next_raffle', 'El próximo sorteo será el'),
  new Message('parking_notVehicle', 'Para dar de alta tu vehículo, puedes hacerlo en Tus vehículos dentro de Tu perfil.'),
  new Message('parking_raffle_info', 'Hoy no tienes plaza de parking'),
  new Message('parking_raffle_info_not_available', 'Sorteo no disponible'),
  new Message('parking_enter_raffle', 'Entrar al sorteo'),
  new Message(
    'parking_yieldTemporary_message',
    'Usa la opción “Ceder temporalmente” para periodos de vacaciones o ausencias. Puedes determinar hasta 3 periodos en los que deseas ceder tu plaza.'
  ),
  new Message(
    'parkingOne_checkedRaffle_title',
    'Estás participando en el sorteo'
  ),
  new Message(
    'parkingOne_checkedRaffle_description',
    'Mientras mantenga esta opción activa, todos los días entrarás en el sorteo de plazas de parking. Recuerda desactivarla en periodo de vacaciones o ausencias. '
  ),
  new Message(
    'parkingOne_no_checkedRaffle_title',
    'No estás participando en el sorteo'
  ),
  new Message(
    'parkingOne_checkedRaffle_description_second',
    'Si quieres saber cómo funciona el sorteo de plazas, pulsa'
  ),
  new Message(
    'parkingOne_raffle_end',
    'Error, el sorteo está borrado o deshabilitado'
  ),
  new Message(
    'parking_without_assigned_parkingSpot',
    'No tienes plaza asignada hoy'
  ),
  new Message(
    'parking_with_assigned_parkingSpot_today',
    'Tu plaza para hoy es '
  ),
  new Message('parking_yielded_parkingSpot', 'Tu plaza está cedida hasta el '),
  new Message('parking_yielded_parkingRecover', 'Recuperarás tu plaza el '),
  new Message('parking_yielded_parkingConfirm', 'Has cancelado la cesión de tu plaza'),
  new Message('parking_assigned_parkingSpot', 'Tienes plaza hasta '),
  new Message('parking_assigned_fixed_parkingSpot', 'Tienes plaza fija asignada '),
  new Message('parking_ceded_square', 'Plaza cedida'),
  new Message('parking_request_seeDetails_btn', 'Ver detalles'),
  new Message('parking_request_btn', 'Solicitar plaza'),
  new Message(
    'parking_parkingSpot_absence',
    'Puedes ceder tu plaza en caso de ausencia o vacaciones'
  ),
  new Message('parking_user_message', 'Plaza cedida hasta próximo sorteo'),
  new Message('parking_temporal_message', 'Plaza cedida temporalmente'),
  new Message(
    'parking_request_periods',
    'Para solicitar plaza, selecciona hasta 3 períodos'
  ),
  new Message('parking_yield_temporary', 'Ceder temporalmente'),
  new Message('parking_from_date', 'Desde'),
  new Message('parking_to_date', 'Hasta'),
  new Message('parking_disabled_reservation_title', 'Parking liberado'),
  new Message('parking_disabled_reservation_description', 'Hemos liberado el parking durante la crisis del COVID-19'),

  new Message('send_invite_btn', 'Invitar'),
  new Message('send_invitation', 'Enviar invitación'),
  new Message('user_to_send_invite', 'Buscar usuarios…'),
  new Message('search_user', 'Buscar persona'),
  new Message(
    'search_users_to_send_invitation',
    'Elige a los usuarios a los que quieres enviarles la invitación:'
  ),
  new Message('invitation_user_not_found', 'No se han encontrado usuarios'),
  new Message('invitation_sent', 'La invitación se ha enviado correctamente'),
  new Message(
    'invitations_users_search_error',
    'Ha ocurrido un problema al buscar los usuarios, por favor inténtalo de nuevo'
  ),
  new Message(
    'invitations_users_department_search_error',
    'Ha ocurrido un problema al buscar por usuario o departamento, por favor inténtalo de nuevo'
  ),
  new Message(
    'invitations_users_send_error',
    'Ha ocurrido un problema al enviar la invitación, por favor inténtalo de nuevo'
  ),
  new Message('invitations_user_not_registered', 'Usuario no registrado'),
  new Message('reservation_pending_confirmation', 'Solicitada'),
  new Message('room_reserve_byRoomAndPeriod_btn', 'Buscar por sala y periodo'),
  new Message(
    'search_reservations_periods_by_room',
    'Encontrar huecos para una sala'
  ),
  new Message('hours', 'Horas'),
  new Message('time_str_from', 'De'),
  new Message('time_str_to', 'a'),
  new Message('floor', 'Planta'),
  new Message('select_floor', 'Seleccione planta'),
  new Message('area', 'Espacio'),
  new Message('select_area', 'Seleccione espacio'),
  new Message('resource', 'Recurso'),
  new Message('select_resource', 'Seleccione recurso'),
  new Message('categorie', 'Categoría'),
  new Message('select_categorie', 'Seleccione categoría'),
  new Message('observation', 'Observaciones'),
  new Message('send_report', 'Enviar reporte'),
  new Message('title_incidence', 'Reportar incidencia'),
  new Message('box_title', 'Selecciona un puesto para reservarlo'),
  new Message('box_title_legend', 'Leyenda'),
  new Message('box_free', 'Libre'),
  new Message('box_partially_occupied', 'Parcialmente ocupado'),
  new Message('box_occupied', 'Ocupado'),
  new Message('box_disabled', 'Deshabilitado'),
  new Message('box_reserved', 'Reservado'),
  new Message('box_yourDesk', 'Tu puesto'),
  new Message('box_userSelectDesk', 'Puesto usuario seleccionado'),
  new Message('box_position_available', 'Puestos seleccionados'),
  new Message('reserveDesk_title', 'Reservar puesto de trabajo'),
  new Message('reserveDeskDinning_title', 'Reservar puesto comedor'),
  new Message('reserveDesk_busy', 'Ocupada'),
  new Message('reserveDesk_toSelect', 'SELECCIONAR'),
  new Message('reserveDesk_selected', 'SELECCIONADO'),
  new Message('reserveDesk_cancel', 'CANCELAR'),
  new Message('reserveDesk_reserve', 'RESERVAR'),
  new Message('reserveDesk_reserve_all', 'JORNADA COMPLETA'),
  new Message('reserveDesk_edit', 'EDITAR'),

  new Message('message_DeskNotSelected_Title', 'No se puede seleccionar'),
  new Message('message_DeskConsecutive_Description', 'Sólo se pueden reservar tramos horarios consecutivos.'),
  new Message('message_DeskNot_Description', 'No se pueden seleccionar más tramos horarios.'),

  new Message('message_DeskNotSelected_Title--NotSelect', 'No se puede reservar'),
  new Message('message_DeskConsecutive_Description--NotSelect', 'Para realizar la reserva debe seleccionar al menos un tramo horario.'),

  // Security
  new Message('security_search_title', 'Buscar por'),
  new Message('security_search_select', 'Seleccione una opción'),
  new Message('security_search_name', 'Nombre'),
  new Message('security_search_email', 'Dirección de correo'),
  new Message('security_search_code', 'Código de plaza'),
  new Message('security_search_model', 'Modelo'),
  new Message('security_search_plate', 'Matrícula'),
  new Message('security_search_place', 'Nº Plaza'),
  new Message('security_search_building', 'Edificio'),
  new Message('security_search_floor', 'Planta'),
  new Message('security_search_btn', 'Buscar'),
  new Message('security_search_value', 'Texto'),
  new Message('security_search_value_error', 'Por favor, introduzca al menos 3 caracteres'),
  new Message('security_search_by_name', 'No existen resultados para dicho nombre'),
  new Message('security_search_by_plate', 'No existen resultados para dicha matrícula'),
  new Message('security_search_by_place', 'No existen resultados para dicha plaza'),
  new Message('security_search_service_error', 'Oops, un error inesperado ocurrió'),
  new Message('security_search_results', 'Resultados'),
  new Message('security_search_places_available_error', 'Error al obtener las plazas disponibles'),
  new Message('security_assign_place_btn', 'Asignar plaza'),
  new Message('security_enrollment_value', 'Matrícula'),
  new Message('security_search_assign_place_error', 'Error en la asignación de plaza'),
  new Message('security_search_found_vehicle_error', 'Vehículo no encontrado'),
  new Message('security_search_headquarters_vehicle_error', 'El vehículo no pertenece a la sede'),
  new Message('security_search_assign_place_ok', 'Plaza asignada correctamente'),
  new Message('security_search_available_places', 'Plazas disponibles'),
  new Message('security_search_result_list', 'Búsqueda de plazas'),

  // Reservation by position
  new Message('reservation_by_position_title', 'Reservar un puesto'),
  new Message('reservation_by_position_btn', 'Reserva'),
  new Message('reserved_seats', 'Puestos reservados'),
  new Message('no_reserved_seats', 'No tienes puestos reservados'),
  new Message('reservation_recover_desk_error', 'Error al recuperar los puestos reservados'),
  new Message('reservation_desk_success', 'Su puesto se ha reservado correctamente.'),
  new Message('reservation_desk_error', 'Se ha producido un error durante la reserva.'),
  new Message('reservation_checkin_success', 'El check-in se ha realizado correctamente.'),
  new Message('reservation_checkin_error', 'No se ha podido realizar el check-in.'),
  new Message('reservation_checkout_success', 'El check-out se ha realizado correctamente.'),
  new Message('reservation_checkout_error', 'No se ha podido realizar el check-out.'),
  new Message('reservation_not_available', 'No se pudo reservar. Ya tiene una reserva de un puesto en la misma fecha.'),
  new Message('reservation_give', 'Cancelar'),
  new Message('reservation_edit', 'Editar'),
  new Message('reservation_checkin', 'Check-In'),
  new Message('reservation_checkout', 'Check-Out'),
  new Message('reservation_edit_success', 'Se ha modificado su reserva correctamente.'),
  new Message('reservation_edit_error', 'Se ha producido un error durante la modificación.'),
  new Message('reservation_cancel_success', 'Se ha cancelado su reserva correctamente.'),
  new Message('reservation_cancel_error', 'Se ha producido un error durante el proceso.'),
  new Message('reservation_historical_title', 'Histórico'),
  new Message('reservation_historical_description', 'Aún no tienes reservas finalizadas'),
  new Message('reservation_permissions', 'No puede realizar más reservas en este periodo.'),
  new Message('reservation_load_more', 'Cargar más'),
  new Message('reservation_share', 'Compartir'),
  new Message('reservation_add_people', 'Añadir personas'),
  new Message('reservation_by', 'por '),
  new Message('reservation_busy_by', 'OCUPADA POR'),
  new Message('reservation_by_admin', 'Reservado por '),
  new Message('reservation_menu_user', 'Buscar puesto usuario'),
  new Message('reservation_menu_multiple', 'Reserva múltiple'),
  new Message('reservation_menu_position', 'Reservar puesto'),
  new Message('reservation_user_position_date', 'Fecha'),
  new Message('reservation_user_position_see_reservations', 'Ver reservas'),
  new Message('reservation_multiple_tab1', 'Datos'),
  new Message('reservation_multiple_tab2', 'Personas'),
  new Message('reservation_multiple_number_of_positions', 'Nº Puestos'),
  new Message('reservation_multiple_number_of_hours', 'Horas'),
  new Message('reservation_multiple_number_of_minutes', 'Minutos'),
  new Message('reservation_multiple_start_date', 'Fecha Inicio'),
  new Message('reservation_multiple_end_date', 'Fecha Fin'),
  new Message('reservation_multiple_see_positions', 'Ver puestos disponibles'),
  new Message('reservation_share_success', 'Se ha compartido tu puesto correctamente'),
  new Message('reservation_share_error', 'Error, no se ha podido compartir tu puesto'),
  new Message('reservation_no_available_positions', 'No hay puestos disponibles para esa selección'),
  new Message('reservation_available_positions_error', 'Error al obtener los puestos disponibles'),
  new Message('reservation_multiple_desk_success', 'Su reserva multiple se ha realizado correctamente'),
  new Message('reservation_search_user', 'Persona'),

  new Message('profile_email_required', 'El email ha de ser válido'),
  new Message('profile_building_selected', 'El edificio es obligatorio'),
  new Message('profile_password_required', 'La contraseña es obligatoria'),
  new Message(
    'profile_newpassword_required',
    'La nueva contraseña es obligatoria'
  ),
  new Message(
    'profile_newpasswordrepeat_required',
    'Debe repetir la nueva contraseña'
  ),
  new Message(
    'profile_newpasswordrepeat_notSame',
    'Las contraseñas no coinciden'
  ),
  new Message('getBuildings_error', 'Error al obtener los edificios'),
  new Message('updateProfile_error', 'Error al actualizar el perfil'),
  new Message('updateProfile_success', 'Perfil actualizado correctamente'),
  new Message('updatePassword_success', 'Contraseña actualizada correctamente'),
  new Message(
    'updatePassword_error',
    'Ha ocurrido un error al cambiar la contraseña.'
  ),

  // Login 
  new Message(
    'user_emailOrName_empty',
    'Alguno de los siguientes campos está vacío: email, nombre. Si el usuario no tiene email no se le mandará correo al realizar una reserva'
  ),
  new Message('login_username', 'Usuario'),
  new Message('login_password', 'Password'),
  new Message('reset_password', 'Password'),
  new Message('reset_newpassword', 'Nuevo Password'),
  new Message('login_username_err', 'El campo usuario es obligatorio'),
  new Message('login_password_err', 'El campo password es obligatorio'),
  new Message(
    'reset_password_msj',
    'Para solicitar una nueva contraseña introduzca'
  ),
  new Message('reset_password_err', 'Debe introducir un email válido'),
  new Message('error_login', 'Se ha producido un error al hacer login'),
  new Message(
    'error_reset',
    'Se ha producido un error al enviar el email. Contacte con el administrador.'
  ),

  // Incidences
  new Message('incidence_file_type', 'Error. El archivo seleccionado no es una imagen'),
  new Message('user_image', 'Imagen'),
  new Message('user_image_optional', '(Opcional)'),
  new Message('no_incidences', 'No hay incidencias creadas'),
  new Message('user_image_select_file', 'Seleccionar archivo'),
  new Message('user_image_no_file', 'Ningún fichero seleccionado'),
  new Message('user_image_file_charge', 'Haga clic para cargar el archivo'),

  // Download
  new Message('dowloads_android_title', 'App SmartBuilding para Android'),
  new Message(
    'dowloads_android_desc',
    'Debes autorizar la instalación en “Ajustes”, “Ajustes avanzados”, “Seguridad” y activar “Apps de origen desconocido”'
  ),
  new Message('dowloads_btn', 'Descargar'),
  new Message('dowloads_ios_title', 'App SmartBuilding para iOS'),
  new Message(
    'dowloads_ios_desc',
    'Recibirás un aviso de que la aplicación proviene de un desarrollador no identificado, debes aceptar la instalación de todos modos (Confiar en el proveedor de servicios)'
  ),
  new Message('dowloads_android_tower_title', 'App Tower para Android'),
  new Message('dowloads_ios_tower_title', 'App Tower para iOS'),

  // Azure
  new Message('login_with_azure', 'Iniciar sesion con SSO'),
  new Message('login_with_azure_loading', 'Realizando autenticación mediante SSO. Por favor espere.'),
  new Message('login_with_azure_loading_error', 'Error. No esta Autorizado'),

  // Profile
  new Message('change_password_btn', 'Cambiar contraseña'),
  new Message('my_vehicles_btn', 'Mis vehículos'),
  new Message('update_password_ok', 'Contraseña actualizada correctamente'),
  new Message('update_password_fail', 'Error, la contraseña introducida no coincide con la suya'),
  new Message('update_password_error', 'Error, no se ha podido actualizar la contraseña'),
  new Message('profile_vehicle_main', 'Principal'),
  new Message('profile_vehicle_edit', 'Editar'),
  new Message('profile_vehicle_remove', 'Eliminar'),
  new Message('profile_vehicleEdit_type_title', 'TIPO DE VEHÍCULO'),
  new Message('profile_vehicleEdit_type_select', 'Selecciona el tipo'),
  new Message('profile_vehicleEdit_license_title', 'MATRICULA'),
  new Message('profile_vehicleEdit_license_placeholder', 'Introduce la matrícula'),
  new Message('profile_vehicleEdit_model_title', 'MODELO'),
  new Message('profile_vehicleEdit_model_placeholder', 'Introduce el modelo'),
  new Message('profile_vehicleEdit_color_title', 'COLOR'),
  new Message('profile_vehicleEdit_color_placeholder', 'Introduce el color'),
  new Message('profile_vehicleEdit_main_title', 'VEHICULO PRINCIPAL'),
  new Message('profile_vehicleEdit_add', 'Añadir vehículo'),
  new Message('profile_vehicleEdit_edit', 'Editar vehículo'),
  new Message('profile_vehicleEdit_cancel', 'Cancelar'),
  new Message('profile_principal_vehicle', 'Vehículo asignado como principal correctamente'),
  new Message('profile_edit_vehicle', 'Vehiculo editado correctamente'),
  new Message('profile_edit_vehicle_error', 'Debe seleccionar otro vehículo como principal previamente'),
  new Message('profile_department', 'Departamento'),
  new Message('profile_my_reservations', 'Hacer públicas mis reservas'),
  new Message('profile_departments_error', 'Error al obtener los departamentos de su sede'),
  new Message('profile_assistance_parking', 'Solicitar plaza de parking'),
  new Message('profile_assistance_parking_description', 'Reserve automáticamente una plaza de aparcamiento al informar de la presencia en la oficina'),
  new Message('profile_assistance_no_vehicle_for_parking', 'Debes registrar un vehículo para poder solicitar plaza de parking'),
  new Message('profile_assistance_dinner', 'Solicitar turno en el comedor'),
  new Message('profile_assistance_dinner_description', 'Reserve automáticamente una franja horaria en el restaurante al informar de la presencia en la oficina. Si no hay franjas horarias disponibles, seleccione una nueva'),
  new Message('support_preferences_btn', 'Preferencias presencia'),
  new Message('support_language_btn', 'Idioma'),
  new Message('profile_form_update_error', 'Se ha producido un error en la actualización de presencia'),
  new Message('profile_form_assistance_config', 'Configuración presencia'),
  new Message('profile_form_vehicle', 'Tus vehículos'),
  new Message('profile_form_pass_mod', 'Modificar contraseña'),
  new Message('profile_form_vehicle_add', 'Vehiculo añadido correctamente'),
  new Message('profile_form_vehicle_delete', 'Vehiculo eliminado correctamente'),
  new Message('profile_form_vehicle_delete_error', 'Error al eliminar el vehículo'),
  new Message('profile_error_image', 'Error. El tamaño es superior a 5MB'),
  new Message('profile_preferred_time_slot', 'PERIODO DE TIEMPO PREFERIDO'),

    // MODAL INDICATE YOUR DEPT
    new Message('indicate_your_dept_title', 'Indica tu departamento'),
    new Message('indicate_your_dept_desc', 
    'Para poder usar la aplicación, el usuario debe seleccionar el departamento al que pertenece.'),
    new Message('indicate_your_dept_bt_cancel', 'CANCELAR'),
    new Message('indicate_your_dept_bt', 'ACEPTAR'),
    new Message('indicate_your_dept_error', 'Debe seleccionar un departamento'),

    // ASSISTANCE
    new Message('assistance_bottom_previous', 'Anterior'),
    new Message('assistance_bottom_next', 'Siguiente'),
    new Message('assistance_bottom_save', 'GUARDAR'),
    new Message('assistance_bottom_solict', 'SOLICITAR'),
    new Message('assistance_bottom_cancel', 'CANCELAR'),
    new Message('assistance_bottom_cancel_assistance', 'Cancelar presencia'),
    new Message('assistance_text_waiting', '(Lista espera)'),
    new Message('assistance_text_confirm', 'Presencia confirmada'),
    new Message('assistance_text_confirmed', 'Presencia confirmada'),
    new Message('assistance_text_not_confirm', 'Confirmar presencia'),
    new Message('assistance_text_cancel_ok', 'Presencia cancelada correctamente'),
    new Message('assistance_text_cancel_ko', 'Error al cancelar la presencia'),
    new Message('assistance_text_confirm_ok', 'Presencia confirmada correctamente'),
    new Message('assistance_update_error', 'Error al confirmar la presencia'),
    new Message('assistance_text_liberate_parking_ok', 'Plaza de parking liberada correctamente'),
    new Message('assistance_text_liberate_parking_ko', 'Error al liberar la plaza de parking'),
    new Message('assistance_text_liberate_waiting_list_ok', 'Lista de espera cancelada correctamente'),
    new Message('assistance_text_liberate_waiting_list_ko', 'Error en la cancelación de la lista de espera'),
    new Message('assistance_text_solict_parking_ok', 'Plaza de parking asignada correctamente'),
    new Message('assistance_text_solict_parking_ko', 'Error en la asignación de plaza de parking'),
    new Message('assistance_text_solict_dinner_ok', 'Turno de comedor asignado correctamente'),
    new Message('assistance_text_solict_dinner_ko', 'Error en la asignación de turno de comedor'),
    new Message('assistance_text_cancel_dinner_ok', 'Turno de comedor cancelado correctamente'),
    new Message('assistance_text_cancel_dinner_ko', 'Error en la cancelación del turno de comedor'),
    new Message('assistance_text_check_daily_menu', 'CONSULTAR MENÚ SEMANAL'),
    new Message('assistance_text_available_shifts', 'TURNOS DISPONIBLES'),
    new Message('assistance_text_available_vehicles', 'TIPO VEHÍCULO'),
    new Message('assistance_text_legend', 'Leyenda'),
    new Message('assistance_text_reserve_error', 'Ha superado el número máximo de reservas'),
    new Message('assistance_text_turn_selection_error', 'Debes elegir un turno'),
    new Message('assistance_text_legend_alert', 'Capacidad excedida. Revise la planificación de presencia en la oficina'),
    new Message('assistance_configuration_days_search_error', 'Ha ocurrido un error en la búsqueda de la configuración de los días'),
    new Message('assistance_search_error', 'Ha ocurrido un error en la búsqueda de las presencias'),
    new Message('assistance_users_for_day_search_error', 'Ha ocurrido un error en la búsqueda de usuarios'),
    new Message('assistance_info_search_error', 'Ha ocurrido un error en la búsqueda de la información de la presencia'),
    new Message('assistance_candidates_search_error', 'Ha ocurrido un error en la búsqueda de presencias candidatas'),
    new Message('assistance_user_manager_search_error', 'Ha ocurrido un error en la búsqueda de los usuarios'),
    new Message('assistance_select_user', 'Seleccione usuario: '),
    new Message('assistance_button_confirm', 'Confirmar'),
    new Message('assistance_button_close', 'Cerrar'),
    new Message('assistance_dialog_confirm_title', 'Cancelar Presencia'),
    new Message('assistance_dialog_confirm_text', '¿Desea cancelar la presencia? Se eliminarán los siguientes elementos: '),
    new Message('assistance_dialog_confirm_text_assistance', 'Presencia'),
    new Message('assistance_dialog_confirm_text_assign_place', 'Plaza asignada'),
    new Message('assistance_dialog_confirm_text_restaurant_shift', 'Turno de comedor'),

    // CALENDAR NOT CONFIRM 
      //PARKING
    new Message('assistance_text_not_space_available', 'No hay plaza de parking disponible'),
    new Message('assistance_text_not_vehicle_assign', 'No tiene vehículos registrados en su perfil'),
    new Message('assistance_text_parking_not_space_available_waiting', 'No hay plaza de parking disponible. Puedes entrar en lista de espera.'),
    new Message('assistance_text_parking_complete_space', 'Puede completar la reserva de plaza de parking para el vehículo seleccionado'),
    new Message('assistance_text_parking_not_complete_space_selected', 'No se puede completar la reserva de parking para el vehículo seleccionado. Por favor, selecione otro tipo de vehículo'),
    new Message('assistance_text_parking_available', 'Hay plazas de parking disponibles'),
    new Message('assistance_text_not_request_parking', 'No hay plazas de parking disponibles para sus vehículos registrados'),
    new Message('assistance_text_not_space_available_contactSystem', 'No se pudo reservar, contacte con el administrador del sistema'),
      //DINNER
    new Message('assistance_text_not_dining_room_service', 'No hay servicio de comedor para este día'),
    new Message('assistance_text_not_assign_dinner_space', 'No hay turno libre en el comedor'),
    new Message('assistance_text_not_assign_dinner_space_hour_select', 'No hay turno libre en el comedor a la hora seleccionada'),
    new Message('assistance_text_assign_dinner_space', 'Tienes reservado turno en el comedor'),
    new Message('assistance_text_dinner_select', 'Seleccione turno de comedor'),
    new Message('assistance_text_not_request_dinner_space', 'No se pudo reservar, contacte con el administrador del sistema'),
    new Message('assistance_text_not_dining_room_service_workingHome_partTime', 'No hay servicio de comedor para este día'),

    // CALENDAR CONFIRM
     //PARKING
    new Message('assistance_text_assign_parking', 'Tienes plaza en el {0}'),
    new Message('assistance_text_assign_parking_space', 'Tienes plaza de parking asignada'),
    new Message('assistance_text_waiting_list', 'Estás en lista de espera'),
    new Message('assistance_text_not_assign_main_active', ''),
    new Message('assistance_text_not_assign_main_not_active', ''),
    new Message('assistance_text_not_assign_parking', ''),
    new Message('assistance_text_not_assign_parking_text_alert', 'Tienes que registrar un vehículo en tu perfil'),
    new Message('assistance_text_not_parking_space_available', 'No hay espacio de estacionamiento disponible'),
      //DINNER
    new Message('assistance_text_assign_dinner', 'Tienes reservado turno en el comedor a las '),
    new Message('assistance_text_available_dinner_space', 'Puedes reservar una plaza en el comedor'),
    new Message('assistance_text_not_assign_dinner', 'No tienes reservado turno en el comedor'),

  // DINNING OCCUPATION
  new Message('occupation_text_free', 'Libre'),
  new Message('occupation_text_occupied', 'Ocupado'),
  new Message('occupation_text_occupancy_level', 'Nivel de ocupación en tiempo real'),
  new Message('occupation_text_last_update', 'Última vez actualizado a las '),

    // PARKING OCCUPATION
    new Message('parking_occupation_title', 'Aparcamiento'),
    new Message('parking_occupation_free_places', 'Plazas libres'),
    new Message('parking_occupation_building_a', 'EDIFICIO A'),
    new Message('parking_occupation_building_b', 'EDIFICIO B'),
    new Message('parking_occupation_schedule', 'Perfil horario de ocupación'),
    new Message('parking_occupation_normal_parking', 'Parking normal'),
    new Message('parking_occupation_electric_parking', 'Parking eléctrico'),
    new Message('parking_occupation_external_error', 'Fallo en servidor Obix al obtener datos de ocupación'),

  // CANTINE
  new Message('cantine_text_error_data', 'Error al obtener los datos'),

  // MODAL LANGUAGE
  new Message('language_assign', 'Asignar idioma'),
  new Message('language_text_available', 'Idiomas disponibles'),
  new Message('language_es', 'Español'),
  new Message('language_en', 'Inglés'),
  new Message('language_pt', 'Portugués'),
  new Message('language_pt-br', 'Portugués Brasileño'),
  new Message('language_update_ok', 'Se ha actualizado el idioma correctamente'),
  // LOPD PUIG
  new Message('lopd_text_title', 'Política de privacidad'),
  new Message(
    'lopd_text_subtitle',
    'Bienvenido a nuestro Sitio web/a la aplicación <b>SmartBuilding</b> (el <b>“Sitio web y/o Aplicativo”</b>).' +
      'Lea detenidamente nuestra Política de privacidad.'
  ),
  new Message('lopd_text_our_commitment_title', 'Nuestros compromisos'),
  new Message(
    'lopd_text_our_commitment_parraf_1',
    'Todo el mundo tiene derecho a proteger sus datos personales. Respetamos el derecho de los usuarios ' +
    'a ser informados sobre la recogida de sus Datos personales y otras operaciones relacionadas con dichos datos. ' +
    'Al utilizar datos que puedan identificarle de forma directa o indirecta,' +
    ' aplicaremos un principio de necesidad estricta. Por este motivo, hemos diseñado el Sitio web y/o Aplicativo' +
    ' de tal manera que el uso de sus Datos personales se realizará de forma mínima y no excederá los fines ' +
    'para los cuales se recogieron o trataron sus Datos personales; no trataremos los mismos cuando podamos ' +
    'prestarle servicios a través de la utilización de datos de carácter anónimo o de tráfico, ' +
    'así como mediante otros medios que nos permitan identificarle, aparte de cuando sea estrictamente necesario ' +
    'o previa solicitud por parte de las autoridades públicas competentes o la policía (por ejemplo, ' +
    'en caso de datos de tráfico o su dirección MAC/IP de su dispositivo).'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_2',
    'A efectos de la Legislación de protección de datos (según se define más adelante), ANTONIO PUIG S.A. ' +
    '(en adelante, <b>“APSA”</b> o la <b>“Empresa”)</b>, con domicilio social en Plaça Europa, 46-48, 08902 Hospitalet de Llobregat ' +
    '(Barcelona), titular del Número de Identificación Fiscal B-64.797.129, inscrita en el Registro Mercantil ' +
    'de Barcelona, en el tomo 40.696, folio 197, número de hoja B-371.911, se considerará el responsable del ' +
    'tratamiento de sus Datos personales. La Empresa se compromete a tratar toda la información proporcionada ' +
    'en línea por un usuario como estrictamente confidencial. '
  ),
  new Message(
    'lopd_text_our_commitment_parraf_3',
    'Esta Política de privacidad se refiere a la información personal que identifica a cualquier usuario ' +
    'al acceder a nuestro Sitio web y/o Aplicativo, a utilizar sus servicios o a rellenar formularios en nuestro ' +
    'Sitio web y/o Aplicativo con o sin necesidad de comprar ningún producto (en adelante, <b>“Datos personales”</b>). ' +
    'Esta Política de privacidad le ayuda a entender cómo recopila y utiliza la Empresa sus Datos personales ' +
    'y para qué fines, y enumera sus derechos en relación con sus Datos personales.'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_4',
    'Al utilizar este Sitio web y/o Aplicativo, Ud. acepta las prácticas descritas en esta Política de privacidad. ' +
    'Si no desea aceptar las prácticas descritas en esta Política de privacidad, no utilice el Sitio web y/o Aplicativo.'
  ),
  new Message(
    'lopd_text_our_commitment_parraf_5',
    'Debe comunicar a nuestro Departamento de Recursos Humanos cualquier cambio en los Datos personales con el ' +
    'fin de garantizar que la información contenida en nuestros archivos sea, en todo momento, actualizada y precisa. ' +
    'Ud. es responsable, en todos los casos, de la exactitud de los Datos personales proporcionados, y nos reservamos ' +
    'el derecho a suspender o interrumpir la prestación de los servicios solicitados en caso de que proporcione ' +
    'Datos personales inexactos, sin perjuicio de ninguna acción permitida por la ley. '
  ),
  new Message('lopd_text_our_commitment_parraf_6',
  'Para cualquier problema o pregunta relacionada con esta Política de privacidad, puede ponerse en contacto ' +
  'con nuestro Departamento de Recursos Humanos: <b>hrpersonaldata@puig.com</b>'),
  new Message('lopd_text_applicable_legislation_title', 'Legislación aplicable'),
  new Message('lopd_text_applicable_legislation_parraf1',
   'Todos los Datos personales enviados a la Empresa a través del Sitio web y/o Aplicativo serán recogidos o ' +
   'tratados por la Empresa conforme a la legislación de protección de datos aplicable, incluido el Reglamento ' +
   '2016/679 de la UE relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos ' +
   'personales y a la libre circulación de estos datos (Reglamento General de Protección de Datos o RGPD), ' +
   'sin perjuicio de cualesquiera otras normas nacionales vigentes o que en el futuro puedan promulgarse ' +
   'sobre la materia, así como las leyes locales obligatorias como la Ley Orgánica 3/2018, de 5 de diciembre, ' +
   'de Protección de Datos Personales y garantía de los derechos digitales (conjuntamente, <b>“Legislación de protección ' +
   'de datos”</b>).'),
   new Message('lopd_text_personal_data_title', '¿Quién recopila y trata sus Datos personales, cómo y con qué finalidad? '),
   new Message('lopd_text_personal_data_parraf1',
   '<b>Datos personales que Ud. nos proporciona:</b>  a través de los correspondientes formularios del Sitio web ' +
   'y/o Aplicativo, dichos Datos personales (nombre, apellidos, correo electrónico profesional, matrícula del ' +
   'vehículo particular, otros datos profesionales, etc.) podrán ser tratados para finalidades relacionadas ' +
   'con el control del aforo y del acceso a las zonas comunes, orientadas a la prevención de riesgos que afecten ' +
   'a la protección de las personas, locales y bienes patrimoniales gestionados por APSA o cualquier empresa del ' +
   'Grupo PUIG, en particular, el control de acceso al edificio, al parking y al comedor. ' +
   '(en el presente documento, <b>“Fines de seguridad”</b>).'),
   new Message('lopd_text_personal_data_parraf2', 'La base legal para el tratamiento de sus datos personales en función ' +
   'de la finalidad anteriormente descrita, se basa en nuestro interés legítimo de velar por la salud y la seguridad del ' +
   'personal, en virtud del mandato establecido en la Ley 31/1995, de 8 de noviembre, de Prevención de Riesgos Laborales.'),
   new Message('lopd_text_personal_data_parraf3',
   'Los motivos para recopilar Datos personales se enumerarán expresamente en la información de la declaración ' +
   'de privacidad que presentaremos cada cierto tiempo en el apartado del Sitio web y/o Aplicativo que solicite ' +
   'al Usuario que proporcione sus Datos personales, en relación con los Fines de seguridad.'),
   new Message('lopd_text_no_personal_data_title', '¿Qué sucede si no nos proporciona sus datos personales?'),
   new Message('lopd_text_no_personal_data_parraf1',
   'La negativa a proporcionarnos algunos de sus Datos personales necesarios para realizar los fines anteriores puede, ' +
   'por consiguiente, suponer la imposibilidad de poder tramitar la reserva para el acceso a las instalaciones de APSA ' +
   'o cualquier empresa del Grupo PUIG. '),
   new Message('lopd_text_no_personal_data_parraf2',
   'La divulgación de Datos personales adicionales a nosotros que no sean necesarios para cumplir con las obligaciones ' +
   'legales o contractuales es, por lo contrario, opcional y no tiene ningún efecto sobre el uso del Sitio web y/o ' +
   'Aplicativo. '),
   new Message('lopd_text_disclosure_personal_data_title', '¿A quién se divulgarán sus datos personales?'),
   new Message('lopd_text_disclosure_personal_data_parraf1', 'APSA ha contratado la prestación de determinados servicios ' +
   'a proveedores, los cuales podrían tener acceso y/o tratar datos personales en su condición de encargados del ' +
   'tratamiento (en adelante, <b>“Encargados del tratamiento”</b>), en particular: '),
   new Message('lopd_text_disclosure_personal_data_parraf2', 'Proveedores de alojamiento y mantenimiento del Sitio web ' +
   'y/o Aplicativo y de soluciones de software corporativo que lo requieran;'),
   new Message('lopd_text_disclosure_personal_data_parraf3', 'Proveedores de servicios de atención a los derechos de los afectados.'),
   new Message('lopd_text_disclosure_personal_data_parraf4', 'En cualquier caso, sus datos personales no serán ' +
   'transferidos a países localizados fuera del Espacio Económico Europeo que no ofrezcan un nivel adecuado o no ' +
   'aporten las garantías adecuadas de protección conforme a lo establecido en Legislación de protección de datos.'),
   new Message('lopd_text_disclosure_personal_data_parraf5', 'Adicionalmente, podremos comunicar sus datos a las ' +
   'autoridades competentes cuando esté contemplado legalmente. Además, sus Datos personales podrán divulgarse a las ' +
   'autoridades públicas (p. ej., policía o judicial) de conformidad con las leyes aplicables y a petición formal de ' +
   'dichas entidades.'),
   new Message('lopd_text_disclosure_personal_data_parraf6', 'Sus Datos personales podrán estar destinados a las ' +
   'empresas pertenecientes al Grupo PUIG, con domicilio social en países de la UE o países ajenos a la UE, ' +
   'para sus propios Fines de seguridad.'),
   new Message('lopd_text_security_measures_title', 'Medidas de seguridad y duración de la conservación'),
   new Message('lopd_text_security_measures_parraf1', 'Hemos adoptado medidas de seguridad para proteger los Datos ' +
   'personales frente a destrucción accidental o ilícita, pérdida accidental, alteración, divulgación o acceso no ' +
   'autorizados, y contra cualquier otro motivo del tratamiento de datos que no cumpla con nuestra Política de privacidad.'),
   new Message('lopd_text_security_measures_parraf2', 'Para la mejor protección posible de sus Datos personales fuera ' +
   'de los límites de nuestro control y gestión del mismo, es aconsejable que su dispositivo cuente con soluciones de ' +
   'software que protejan la transmisión/recepción de datos de red (como sistemas antivirus actualizados) y que su ' +
   'proveedor de servicios de Internet tome las medidas adecuadas para la seguridad de la transmisión de datos de red ' +
   '(como, por ejemplo, cortafuegos y filtrado antispam).'),
   new Message('lopd_text_security_measures_parraf3', 'Solo conservaremos sus Datos personales durante el tiempo que sea ' +
   'necesario para cumplir los fines establecidos en la presente Política de privacidad. Cuando ya no necesitemos tratar ' +
   'sus Datos personales para los fines establecidos en esta Política de privacidad o haya ejercido su derecho de ' +
   'supresión, eliminaremos sus Datos personales de nuestro sistema.'),
   new Message('lopd_text_data_transfer_title', 'Transferencia de sus datos personales a otros países'),
   new Message('lopd_text_data_transfer_parraf1', 'Los Datos personales que recopilamos de Ud. se encuentran actualmente ' +
   'dentro de la Unión Europea (“UE”). '),
   new Message('lopd_text_data_transfer_parraf2', 'Sin embargo, es posible que, en el futuro, dichos Datos personales ' +
   'sean transferidos, almacenados o tratados fuera de la UE o del Espacio Económico Europeo (“EEE”). En ese supuesto, ' +
   'tus datos únicamente serán transferidos a países localizados fuera de la Unión Europea sobre los que: (i) exista una ' +
   'decisión de adecuación de la Comisión Europea; o (ii) se aporten las garantías apropiadas de protección ' +
   '(esto es, Cláusulas tipo de protección de datos adoptadas por la Comisión Europea o una autoridad de control, ' +
   'Normas corporativas vinculantes, Códigos de conducta, Mecanismos de certificación o cláusulas contractuales ad-hoc).'),
   new Message('lopd_text_right_personal_data_title', 'Su derecho en relación con sus Datos personales'),
   new Message('lopd_text_right_personal_data_parraf1', 'A continuación, se muestra un resumen de los derechos ' +
   'disponibles en relación con sus Datos personales. '),
   new Message('lopd_text_right_personal_data_parraf2', 'Para su comodidad, y sin perjuicio de determinados ' +
   'requisitos formales establecidos en la Legislación de protección de datos, puede ejercer cualquiera de estos derechos ' +
   'poniéndose en contacto con nosotros en <b>hrpersonaldata@puig.com</b>'),
   new Message('lopd_text_withdraw_consent_title', 'Derecho a retirar su consentimiento:'),
   new Message('lopd_text_withdraw_consent_parraf1', 'En cualquier momento, puede retirar el consentimiento que otorga ' +
   'a la Empresa para tratar sus Datos personales. Tenga en cuenta, sin embargo, que, en el caso de que retire su ' +
   'consentimiento o se oponga de otro modo a nuestro tratamiento de sus Datos personales, esto puede afectar la ' +
   'funcionalidad de nuestro Sitio web y/o Aplicativo.'),
   new Message('lopd_access_personal_data_title', 'Derecho a acceder a sus Datos personales en nuestra posesión:'),
   new Message('lopd_access_personal_data_parraf1', 'Ud. tiene derecho a obtener, en cualquier momento, la confirmación ' +
   'de si estamos tratando o no sus Datos personales y, en su caso, el acceso a dichos Datos personales. '),
   new Message('lopd_access_personal_data_parraf2', 'Además, Ud. tiene derecho a recibir información sobre la fuente de ' +
   'sus Datos personales; los fines y la forma de tratar sus Datos personales; la lógica implicada en cualquier ' +
   'tratamiento de datos electrónicos; los detalles del responsable del tratamiento de datos y de los encargados del ' +
   'tratamiento de datos; los nombres de las entidades y categorías de entidades a las que se pueden revelar sus Datos ' +
   'personales o que pueden acceder a sus Datos personales, por ejemplo, como responsable del tratamiento de datos o ' +
   'una parte así designada. '),
   new Message('lopd_text_right_rectify_data_title', 'Derecho a rectificar los Datos personales inexactos:'),
   new Message('lopd_text_right_rectify_data_parraf1', 'Tiene derecho a obtener de nosotros sin demora indebida la ' +
   'rectificación de Datos personales inexactos que conservamos y que le afecten. Esto incluye el derecho a solicitar ' +
   'que se completen los Datos personales incompletos. '),
   new Message('lopd_text_right_to_erasure_title', 'Derecho de supresión:'),
   new Message('lopd_text_right_to_erasure_parraf1', 'Ud. tiene derecho a obtener de nosotros la supresión sin demora ' +
   'indebida de los Datos personales que conservamos y que le incumban a Ud., en las circunstancias estipuladas por ' +
   'la Legislación de protección de datos. '),
   new Message('lopd_text_right_restriction_title', 'Derecho a la restricción del tratamiento:'),
   new Message('lopd_text_right_restriction_parraf1', 'Tiene derecho a restringir la forma en que tratamos sus Datos ' +
   'personales en las circunstancias estipuladas por la Legislación de protección de datos. '),
   new Message('lopd_text_data_portability_right_title', 'Derecho a la portabilidad de los datos:'),
   new Message('lopd_text_data_portability_right_parraf1', 'Ud. tiene derecho a recibir de nosotros los Datos personales ' +
   'relativos a Ud. que nos haya proporcionado, en un formato estructurado, utilizado comúnmente y legible por máquina, ' +
   'y el derecho a transmitir esos datos a otro responsable del tratamiento sin impedimento por nuestra parte. ' +
   'Este derecho incluirá el derecho a exigir que transmitamos los Datos personales pertinentes a otro responsable del ' +
   'tratamiento en su nombre, cuando sea técnicamente factible. Este derecho solo se aplica a los datos personales para ' +
   'los que: (i) obtengamos su consentimiento para tratar, o bien (ii) obtengamos para cumplir nuestras obligaciones ' +
   'contractuales con Ud., y en cada caso en la medida en que tratemos sus Datos personales por medios automatizados.'),
   new Message('lopd_text_right_of_opposition_title', 'Derecho de oposición:'),
   new Message('lopd_text_right_of_opposition_parraf1', 'En determinadas circunstancias y por motivos relacionados con ' +
   'su situación particular, Ud. podrá oponerse al tratamiento de sus datos. APSA dejará de tratar los datos, ' +
   'salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.'),
   new Message('lopd_text_right_of_complaint_title', 'Derecho a presentar una queja sobre nosotros:'),
   new Message('lopd_text_right_of_complaint_parraf1', 'Tiene derecho a ejercer su derecho a presentar una reclamación ' +
   'ante una autoridad de control competente, en particular en el Estado miembro de su residencia habitual, lugar de ' +
   'trabajo o lugar de la presunta infracción si considera que el tratamiento de sus datos personales infringe la ' +
   'Legislación de protección de datos.'),
   new Message('lopd_text_opt-out_title', 'Suscripción/exclusión voluntaria'),
   new Message('lopd_text_opt-out_parraf1', 'Cada vez que se requiera su consentimiento, la Empresa le informará ' +
   'de antemano y le dará la opción de proporcionar o rechazar su consentimiento para el uso de sus Datos personales, ' +
   'incluida su dirección de correo electrónico,  marcando las casillas correspondientes.'),
   new Message('lopd_text_opt-out_parraf2', 'Queremos informarle de que podemos tratar sus Datos personales sin su ' +
   'consentimiento en determinadas circunstancias, como cuando dicho tratamiento sea necesario para llevar a cabo una ' +
   'obligación legal a la que estamos sujetos o cuando dicho tratamiento es necesario para llevar a cabo obligaciones ' +
   'basadas en nuestro interés legítimo.'),
   new Message('lopd_text_contacts_title', 'Contactos'),
   new Message('lopd_text_contacts_parraf1', 'Puede ponerse en contacto con la Empresa en cualquier momento escribiendo a ' +
   'la siguiente dirección de correo electrónico <b>hrpersonaldata@puig.com</b> ' +
   'si tiene cualquier duda o pregunta relativa a esta Política de privacidad y sus Datos personales proporcionados a ' +
   'la Empresa, o si Ud. desea recibir más información sobre cómo tratamos sus Datos personales. '),
   new Message('lopd_text_privacy_policy_title', 'Enmiendas y actualización de esta Política de privacidad '),
   new Message('lopd_text_privacy_policy_parraf1', 'Podemos modificar o simplemente actualizar la totalidad o parte ' +
   'de esta Política de privacidad, incluido cuando se realizan modificaciones a las disposiciones legales o normativas ' +
   'que rigen la protección de datos y protegen sus derechos. Las enmiendas y la actualización de la Política de ' +
   'privacidad serán vinculantes tan pronto como se publiquen en el Sitio web y/o Aplicativo en esta sección. ' +
   'Por lo tanto, se le pedirá que acceda regularmente a esta sección para comprobar la publicación de la Política ' +
   'de privacidad más reciente y actualizada.'),
   new Message('lopd_text_privacy_policy_parraf2', 'Esta Política de privacidad se ha actualizado en abril de 2021.'),
   new Message('lopd_text_foot', '<b>© ANTONIO PUIG S.A., 2021. Todos los derechos reservados.</b>'),

   // ASSISTANCE TO THIRD PARTIES
   new Message('assistance_third_users_error', 'Error en la obtención de usuarios'),

  // MODAL RESERVATION
  new Message('reserve_title', 'Reserva tu Recurso'),
  new Message('reserve_carwash_title', 'Reserva tu Lavadero'),
  new Message('reserve_edit_title', 'Edita tu Recurso'),
  new Message('reserve_carwash_edit_title', 'Edita tu Lavadero'),
  new Message('reserve_toSelect', 'SELECCIONAR'),
  new Message('reserve_selected', 'SELECCIONADO'),
  new Message('reserve_toOccupation', 'OCUPADO'),
  new Message('reserve_cancel', 'CANCELAR'),
  new Message('reserve_reserve_all', 'JORNADA COMPLETA'),
  new Message('reserve_reserve', 'RESERVAR'),
  new Message('reserve_edit', 'EDITAR'),

  // RESOURCE
  //LIST
    new Message('resource_list_title', 'Recursos reservados'),
    new Message('resource_list_not_reserve', 'No tiene recursos reservados'),
    new Message('resource_list_button', 'Reservar recursos'),
    new Message(
      'resource_list_cancel_reservation_ok',
      'Se ha eliminado la reserva de recurso correctamente'
    ),
    new Message(
      'resource_list_cancel_reservation_error',
      'No se ha eliminado la reserva de recurso correctamente'
    ),
    new Message(
      'resource_list_add_reservation_ok',
      'Se ha reservado el recurso correctamente'
    ),
    new Message(
      'resource_list_add_reservation_error',
      'No se ha podido realizar la reserva'
    ),
    new Message(
      'resource_list_edit_reservation_ok',
      'Se ha editado la reserva de recurso correctamente'
    ),
    new Message(
      'resource_list_edit_reservation_error',
      'No se ha editado la reserva de recurso correctamente'
    ),
    //RESERVE
   new Message('resource_reserve_title', 'Reserva un Recurso'),
   new Message('resource_edit_title', 'Editar Reserva'),
   new Message('resource_reserve_type', 'Tipo de recurso'),
   new Message('resource_reserve_type_select', 'Selecciona el tipo'),
   new Message('resource_reserve_license', 'Matrícula'),
   new Message('resource_reserve_date', 'Fecha'),
   new Message('resource_reserve_submit', 'Reservar'),
   new Message('resource_reserve_edit_submit', 'Editar Reserva'),
   new Message('resource_reserve_license_placeholder', 'Introduce la matrícula'),
 
   // CAR WASH
  //LIST
  new Message('carWash_list_title', 'Lavaderos reservados'),
  new Message('carWash_list_card_title', 'Plaza de parking'),
  new Message('carWash_list_not_reserve', 'No tiene lavaderos reservados'),
  new Message('carWash_list_button', 'Reservar lavadero'),
  new Message(
    'carWash_list_cancel_reservation_ok',
    'Se ha eliminado la reserva de lavadero correctamente'
  ),
  new Message(
    'carWash_list_cancel_reservation_error',
    'No se ha eliminado la reserva de lavadero correctamente'
  ),
  new Message(
    'carWash_list_add_reservation_ok',
    'Se ha reservado el lavadero de coche correctamente'
  ),
  new Message(
    'carWash_list_add_reservation_error',
    'No se ha podido realizar la reserva'
  ),
  new Message(
    'carWash_list_edit_reservation_ok',
    'Se ha editado la reserva de lavadero de coche correctamente'
  ),
  new Message(
    'carWash_list_edit_reservation_error',
    'No se ha editado la reserva de lavadero de coche correctamente'
  ),
  //RESERVE
  new Message('carWash_reserve_title', 'Reserva un Lavadero'),
  new Message('carWash_edit_title', 'Editar Reserva'),
  new Message('carWash_reserve_type', 'Tipo de lavaderos'),
  new Message('carWash_reserve_type_select', 'Selecciona el tipo'),
  new Message('carWash_reserve_license', 'Matrícula'),
  new Message('carWash_reserve_date', 'Fecha'),
  new Message('carWash_reserve_submit', 'Reservar'),
  new Message('carWash_reserve_edit_submit', 'Editar Reserva'),
  new Message('carWash_reserve_license_placeholder', 'Introduce la matrícula'),
 
  //QUESTION AND ANSWERS
  //LIST
  new Message('question-and-answers_list_title', 'Cuestionarios en curso'),
  new Message('question-and-answers_list_card_title', 'Cuestionario'),
  new Message(
    'question-and-answers_list_not_progress',
    'No hay cuestionario en curso '
  ),
  new Message('question-and-answers_list_button', 'Nuevo cuestionario'),
  new Message('question-and-answers_list_next_title', 'Continuar'),
  new Message('question-and-answers_list_delete_title', 'Cancelar'),
  new Message(
    'question-and-answers_list_delete_ok',
    'Se ha cancelado correctamente'
  ),
  new Message(
    'question-and-answers_list_delete_error',
    'No se ha cancelado correctamente'
  ),

  //NEW

  new Message(
    'question-and-answers_new_add_error',
    'No se ha añadido la respuesta correctamente'
  ),
  new Message(
    'question-and-answers_new_update_error',
    'No se ha editado la respuesta correctamente'
  ),
  new Message(
    'question-and-answers_new_text_placeholder',
    'Escribe tu respuesta'
  ),
  new Message(
    'question-and-answers_new_file_placeholder',
    'Seleccionar un archivo'
  ),
  new Message('question-and-answers_new_next', 'Siguiente'),

  //RESULT
  new Message('question-and-answers_result_init', 'Iniciada'),
  new Message('question-and-answers_result_finish', 'Finalizada'),
  new Message(
    'question-and-answers_result_finish_admin',
    'Finalizada administración'
  ),
  new Message('question-and-answers_result_button', 'Finalizar'),
  new Message('question-and-answers_result_title', 'Respuesta:'),
  new Message('question-and-answers_result_text', 'Texto:'),
  new Message('question-and-answers_result_file', 'Fichero:'),

  //TYPE
  new Message(
    'question-and-answers_type_title',
    'Seleccione un tipo de cuestión'
  ),
  new Message('question-and-answers_type_next', 'Siguiente'),
  new Message('question-and-answers_type_next_error', 'No se ha creado correctamente'),


   // CUSTOM EXTERNAL MODULE
   new Message('external_module_files', 'Archivos'),
   new Message('external_module_apps', 'Aplicaciones'),
   new Message('external_module_report_incidence', 'Reportar incidencia'),
   new Message('external_module_not_found', 'Módulo no encontrado'),
   new Message('external_module_error', 'Error al obtener el módulo')
];
