import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { finalize } from 'rxjs/operators';
import { SpinnerService } from 'src/app/shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../shared/pipes/messages.pipe';
import { BaseService } from '../../shared/services/base.service';
import { ConfigurationService } from '../../shared/services/configuration.service';
import { FlashMessagesService } from '../../shared/services/flash-messages.service';
import { NavigatorRefreshService } from '../../shared/services/navigator.refresh.service';

@Component({
  selector: 'app-shuttle',
  templateUrl: './shuttle.component.html',
  styleUrls: ['./shuttle.component.scss'],
})
export class ShuttleComponent implements OnInit {
  messagesPipe = new MessagesPipe();

  url: any = null;
  urlSafe: SafeResourceUrl;

  constructor(
    private navigatorRefreshService: NavigatorRefreshService,
    public sanitizer: DomSanitizer,
    private configurationService: ConfigurationService,
    private baseService: BaseService,
    private flashMessagesService: FlashMessagesService,
    private spinnerService: SpinnerService
  ) {
    this.navigatorRefreshService.emitMenuRefreshLinkEvent(
      this.messagesPipe.transform('menu_shuttle')
    );
  }

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.spinnerService.show();
    this.configurationService
      .getData()
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(
        (res) => {
          if (res) {
            this.url = res.shuttleUrl;
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
              this.url
            );
          }
        },
        (error) => {
          this.baseService.showErrorDialog(
            this.flashMessagesService,
            error,
            this.messagesPipe.transform('config_getData_text_error')
          );
        }
      );
  }
}
