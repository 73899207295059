<app-spinner></app-spinner>

<div class="row carWash-list">
  <!-- ### Col 1: List ###  -->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'carWash_list_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-carWash" *ngIf="carWashList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'carWash_list_not_reserve' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card" *ngFor="let carWash of carWashList">
      <div class="detail">
        <span class="detail--title">
          {{ 'carWash_list_card_title' | messagesPipe }}:
          {{ carWash?.nameCarwash }}</span
        >
        <span class="detail--license" *ngIf="carWash?.license">
          {{ 'carWash_reserve_license' | messagesPipe }}:
          {{ carWash?.license }}
        </span>
        <span class="detail--headquarters">
          {{ 'headquarters' | messagesPipe }}
          {{ carWash?.nameHeadquarter }}
        </span>
        <span class="detail--date">
          {{ carWash?.dateFrom | date: 'mediumDate' }}
          {{ 'time_str_from' | messagesPipe }}
          {{ carWash?.dateFrom | date: 'shortTime' }}
          {{ 'time_str_to' | messagesPipe }}
          {{ carWash?.dateTo | date: 'shortTime' }}
        </span>
      </div>
      <span class="state">{{ carWash?.codeCarwash }}</span>

      <!-- Card Options -->
      <div class="card-options">
        <!-- Available Options -->
        <div class="available-options">
          <!-- Cancel -->
          <span
            class="available-options__cancel"
            (click)="cancelCarWashReservation(carWash.id)"
          >
            {{ 'reservation_give' | messagesPipe }}
          </span>

          <!-- Edit -->
          <span
            class="available-options__edit"
            (click)="editCarWashReservation(carWash)"
          >
            {{ 'reservation_edit' | messagesPipe }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <!-- ### Col 2: History ###-->
  <div class="col-md-6">
    <!-- Title -->
    <h2>{{ 'reservation_historical_title' | messagesPipe }}</h2>

    <!-- No reservation -->
    <div class="no-carWash" *ngIf="carWashHistoryList.length <= 0">
      <div class="image">
        <img src="../../assets/img/icon-sala-vacia.png" alt="" />
      </div>
      <span>{{ 'reservation_historical_description' | messagesPipe }}</span>
    </div>

    <!-- List -->
    <div class="card history" *ngFor="let carWashHistory of carWashHistoryList">
      <div class="detail">
        <span class="detail--title">
          {{ 'carWash_list_card_title' | messagesPipe }}:
          {{ carWashHistory?.nameCarwash }}</span
        >
        <span class="detail--license" *ngIf="carWashHistory?.license">
          {{ 'carWash_reserve_license' | messagesPipe }}:
          {{ carWashHistory?.license }}
        </span>
        <span class="detail--headquarters">
          {{ 'headquarters' | messagesPipe }}
          {{ carWashHistory?.nameHeadquarter }}
        </span>
        <span class="detail--date">
          {{ carWashHistory?.dateFrom | date: 'mediumDate' }}
          {{ 'time_str_from' | messagesPipe }}
          {{ carWashHistory?.dateFrom | date: 'shortTime' }}
          {{ 'time_str_to' | messagesPipe }}
          {{ carWashHistory?.dateTo | date: 'shortTime' }}
        </span>
      </div>
      <span class="state">{{ carWashHistory?.codeCarwash }}</span>
    </div>
  </div>
</div>

<!--  ### Buttons ### -->
<div class="add-carWash">
  <button
    type="button"
    class="vdp-icon-mas add"
    (click)="showButtons()"
    [ngClass]="{ closed: showOptionsButtons }"
  ></button>
  <div
    class="carWash-list"
    id="carWash-list"
    [@slideButtons]="getSlideButtonsClass()"
  >
    <div class="carWash-list-buttons">
      <!-- Option 1 -->
      <button type="button" (click)="openReserve()">
        {{ 'carWash_list_button' | messagesPipe }}
        <i class="vdp-buscar-usuario"></i>
      </button>
    </div>
  </div>
</div>

<side-modal></side-modal>
