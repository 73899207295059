import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TreeNextModel } from '../../../core/models/tree.next.model';
import { TreeResumeModel } from '../../../core/models/tree.resume.model';
import { SpinnerService } from '../../../shared/components/spinner/spinner.service';
import { MessagesPipe } from '../../../shared/pipes/messages.pipe';
import { FlashMessagesService } from '../../../shared/services/flash-messages.service';
import { TreeService } from '../../../shared/services/tree.service';

@Component({
  selector: 'app-question-and-answers-new',
  templateUrl: './question-and-answers-new.component.html',
  styleUrls: ['./question-and-answers-new.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0, transform: 'translateX(-100px)' }),
        animate(
          '300ms ease-in',
          style({ opacity: 1, transform: 'translateX(0)' })
        ),
      ]),
      transition(':leave', [
        animate(
          '300ms ease-in',
          style({ opacity: 0, transform: 'translateX(100px)' })
        ),
      ]),
    ]),
  ],
})
export class QuestionAndAnswersNewComponent implements OnInit {
  messagesPipe = new MessagesPipe();

  questionAndAnswersForm: FormGroup;
  nextTreeStage: TreeNextModel = null;
  activeTreeStage: TreeNextModel = null;
  responseActive = null;
  treeUserLdapIdActive: number = null;
  isEdit = false;
  idEdit = null;
  fileBlobEdit = null;

  resumeTreeStage: TreeResumeModel = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private treeService: TreeService,
    private spinnerService: SpinnerService,
    private flashMessagesService: FlashMessagesService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.route.params.subscribe((res) => {
      if (res && res.id) {
        this.treeUserLdapIdActive = res.id;
        this.getTreeNextStage(parseInt(res.id));
        this.getResumeTreeStage(parseInt(res.id));
      }
    });
  }

  initForm() {
    this.questionAndAnswersForm = this.formBuilder.group({
      file: [null],
      text: [''],
      treeEventId: [null, [Validators.required]],
      treeStageId: [null, [Validators.required]],
      treeUserLdapId: [null, [Validators.required]],
    });
  }

  resetForm() {
    this.questionAndAnswersForm.reset();
    this.responseActive = null;
    this.nextTreeStage = null;
    this.activeTreeStage = null;
    this.deleteFile();
  }

  navigateNextTree(id: number) {
    this.spinnerService.show();
    this.isEdit = false;
    this.idEdit = null;
    this.resetForm();
    this.getTreeNextStage(id);
    this.getResumeTreeStage(id);
  }

  editForm(editTreeStage) {
    const treeEventSelect = editTreeStage.treeEvents.find((x) => x.select);
    if (treeEventSelect) {
      this.isEdit = true;
      (this.idEdit = editTreeStage.treeUserLdapStageEventId), this.deleteFile();
      this.responseActive = treeEventSelect;
      this.questionAndAnswersForm.reset({
        file: null,
        text: treeEventSelect.text,
        treeEventId: treeEventSelect.id,
        treeStageId: editTreeStage.treeStage.id,
        treeUserLdapId: this.treeUserLdapIdActive,
      });
      if (treeEventSelect.urlFile) {
        this.donwloadFileUrl(treeEventSelect.urlFile, treeEventSelect.fileName);
      }
    } else {
      this.navigateNextTree(this.treeUserLdapIdActive);
    }
    this.spinnerService.hide();
  }

  donwloadFileUrl(url, fileName) {
    this.treeService.downloadFile(url).subscribe((res) => {
      if (res) {
        this.fileBlobEdit = { blob: res.blob(), fileName: fileName };
        this.createFileEdit(res, fileName);
      }
    });
  }

  createFileEdit(resFile, fileName) {
    let inputElement = document.getElementById('file') as HTMLInputElement;
    let file = new File([resFile.blob()], fileName, {
      type: resFile.blob().type,
      lastModified: new Date().getTime(),
    });
    let container = new DataTransfer();
    container.items.add(file);
    inputElement.files = container.files;
    this.questionAndAnswersForm.get('file').setValue(inputElement.files[0]);
  }

  setEvent(treeStage) {
    if (treeStage.id) {
      this.responseActive = treeStage;
      this.questionAndAnswersForm.get('treeEventId').setValue(treeStage.id);
    }
    this.questionAndAnswersForm.get('text').setValue('');
    this.deleteFile();
  }

  setFile(file: File) {
    this.fileBlobEdit = null;
    this.questionAndAnswersForm.get('file').setValue(file);
  }

  deleteFile() {
    this.fileBlobEdit = null;
    this.questionAndAnswersForm.get('file').reset();
    if (
      document.getElementById('file') &&
      (document.getElementById('file') as any).value
    ) {
      (document.getElementById('file') as any).value = '';
    }
  }

  donwloadFile() {
    if (this.fileBlobEdit.blob) {
      let a = document.createElement('a');
      a.href = URL.createObjectURL(this.fileBlobEdit.blob);
      a.download = this.fileBlobEdit.fileName;
      a.click();
    }
  }

  getResumeTreeStage(id) {
    this.treeService.getResumeTreeStage(id).subscribe({
      next: (response) => {
        if (response) {
          this.resumeTreeStage = response;
        } else {
          this.resumeTreeStage = null;
        }
      },
      error: () => {
        this.resumeTreeStage = null;
      },
    });
  }

  getTreeNextStage(id: number) {
    this.spinnerService.show();
    this.treeService.getTreeNextStage(id).subscribe({
      next: (response) => {
        if (response) {
          this.nextTreeStage = response;
          this.activeTreeStage = response;
          this.questionAndAnswersForm.setValue({
            ...this.questionAndAnswersForm.getRawValue(),
            treeStageId: response.treeStage.id,
            treeUserLdapId: id,
          });
        } else {
          this.nextTreeStage = null;
          this.activeTreeStage = null;
          this.resetForm();
          this.navigateResult(id);
        }
      },
      error: () => {
        this.spinnerService.hide();
        this.nextTreeStage = null;
        this.activeTreeStage = null;
        this.resetForm();
        this.navigateResult(id);
      },
      complete: () => {
        this.spinnerService.hide();
      },
    });
  }

  navigateResult(id: number) {
    this.router.navigate(['menu/questionsAndAnswers/result', id]);
  }

  addTreeStageEvent() {
    const { text, treeEventId, treeStageId, treeUserLdapId, file } =
      this.questionAndAnswersForm.getRawValue();
    this.treeService
      .postAddTreeStageEvent(
        text,
        treeEventId,
        treeStageId,
        treeUserLdapId,
        file
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.navigateNextTree(treeUserLdapId);
          }
        },
        error: () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform('question-and-answers_new_add_error'),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
          this.router.navigate(['menu/questionsAndAnswers/list']);
        },
      });
  }

  getEditTreeStage(id: number) {
    this.spinnerService.show();
    this.treeService.getTreeEditStage(id).subscribe({
      next: (response) => {
        if (response) {
          this.activeTreeStage = response;
          this.editForm(response);
        }
      },
      error: () => {
        this.navigateNextTree(this.treeUserLdapIdActive);
      },
    });
  }

  postUpdateTreeStageEvent() {
    const { text, treeEventId, treeStageId, treeUserLdapId, file } =
      this.questionAndAnswersForm.getRawValue();

    this.treeService
      .postUpdateTreeStageEvent(
        this.idEdit,
        text,
        treeEventId,
        treeStageId,
        treeUserLdapId,
        file
      )
      .subscribe({
        next: (response) => {
          if (response) {
            this.navigateNextTree(treeUserLdapId);
          }
        },
        error: () => {
          this.flashMessagesService.show(
            this.messagesPipe.transform(
              'question-and-answers_new_update_error'
            ),
            {
              cssClass: 'alert-success',
              timeout: 3000,
            }
          );
          this.navigateNextTree(this.treeUserLdapIdActive);
        },
      });
  }
}
