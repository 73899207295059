import {
  DEFAULT_AREA_DATA_API_PATH,
  DEFAULT_AREA_DINNER_DATA_API_PATH,
  DEFAULT_AREA_HOTDESK_DATA_API_PATH,
  DEFAULT_FLOOR_DATA_API_PATH,
  DEFAULT_HOLIDAY_DATA_API_PATH,
  DEFAULT_LANGUAGE,
  DEFAULT_PARKING_DATA_API_PATH,
} from 'src/app/core/constants/const';
import { Environment } from 'src/app/core/models/environment';

export const environment: Environment = {
  production: true,
  use_azure: true,
  clientId: 'ebcde7c4-f802-4f2e-b051-66219ce026c2',
  authority:
    'https://login.microsoftonline.com/9bc3d1cd-55ca-4e13-b5a2-a9e9deaeba3f/',
  redirectUrl: 'https://smartbuilding.alten.es',
  downloadAvailable: true,
  donwloadUrls: {
    android:
      'https://smartbuilding.alten.es/descargas/Alten_Smartbuilding.apk',
    ios: 'https://smartbuilding.alten.es/descargas/manifest.plist',
  },
  enableLopd: false,
  hiddenCountryAndProvince: true,
  showHeadquarterProfile: true,
  notCheckAllowHeadquarter: true,
};
export let NAME = 'Alten';
export const SERVICE_BASE_URL = 'https://smartbuilding.alten.es/ws';
export const COMPONENT_OAUTH_PATH = 'registerToken';
export const LOGIN_RETURN_URL = `https://smartbuilding.alten.es/ws/${COMPONENT_OAUTH_PATH}`;
export const CLIENT_ID = '6b6e84630bdf431d6bff613e3a5c48a9';
export const SERVICE_LOGIN_URL = `${SERVICE_BASE_URL}/web/access?return_url=${encodeURIComponent(
  LOGIN_RETURN_URL
)}&client_id=${CLIENT_ID}`;
export const PARKING_DATA_API_PATH = DEFAULT_PARKING_DATA_API_PATH;

export const AREA_DATA_API_PATH = DEFAULT_AREA_DATA_API_PATH;

export const FLOOR_DATA_API_PATH = DEFAULT_FLOOR_DATA_API_PATH;
export const AREA_DINNER_DATA_API_PATH = DEFAULT_AREA_DINNER_DATA_API_PATH;
export const AREA_HOTDESK_DATA_API_PATH = DEFAULT_AREA_HOTDESK_DATA_API_PATH;

export const HOLIDAY_DATA_API_PATH = DEFAULT_HOLIDAY_DATA_API_PATH;

export const LANGUAGE = DEFAULT_LANGUAGE;
