<div class="main-container">
  <div class="fadeInLayer">
    <div class="list-incidence">
      <!-- No incidences -->
      <div class="no-incidences" *ngIf="listIncidence?.length <= 0">
        <div class="image">
          <img src="../../../assets/img/icon-incidencia-vacia.png" alt="" />
        </div>
        <span>{{ 'no_incidences' | messagesPipe }}</span>
      </div>
      <div class="card" *ngFor="let incidence of listIncidence">
        <div class="detail">
          <span class="detail--title">{{ incidence?.description }}</span>
          <span class="detail--building"
            >{{ incidence?.building }}, {{ incidence?.floor }},
            {{ incidence?.area }}, {{ incidence?.resource }}</span
          >
          <span class="detail--date">{{ incidence?.date }}</span>
        </div>
        <span class="state">{{ incidence?.state }}</span>
      </div>
    </div>
    <div class="add-incidence">
      <button
        type="button"
        class="vdp-icon-mas add"
        (click)="openSideModal()"
      ></button>
    </div>
    <side-modal></side-modal>
  </div>
</div>
